<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular title">
        Test - NID Info - {{ type[0].toUpperCase() + type.slice(1) }}
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card>
      <v-row justify="center">
        <v-col md="8" sm="10" class="pt-10 text-center">
          <div class="">
            <v-text-field
              outlined
              v-model="nid"
              type="number"
              required
              label="NID No:"
              placeholder="10, 13 or 17 digits"
            />
          </div>
          <div class="">
            <v-text-field outlined v-model="dob" label="DOB" required placeholder="yyyy-MM-dd" />
          </div>
          <div class="">
            <v-btn color="success" :disabled="!(nid && dob)" @click="parseNid" :loading="loading">
              <v-icon>mdi-cloud-upload</v-icon>&nbsp; Parse NID
            </v-btn>
          </div>
        </v-col>
        <v-col v-if="parsedNidData" md="11" lg="8" class="pt-4 text-center">
          <v-card class="my-5">
            <v-simple-table :loading="loading" class="text-right">
              <template v-slot:default>
                <tbody>
                  <tr v-for="item in items" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td class="text-left table-value text--monospaced pa-3">
                      {{ emptyChecker(item.value(parsedNidData)) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col v-else-if="errorData" md="12" class="pt-4 text-center">
          <v-card dark class="my-5 pa-3">
            <div class="overflow-x-auto">
              <pre class="red--text text-left">{{ JSON.stringify(errorData, null, 4) }}</pre>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { NID_SCRAPER_SCRAPE, PORICHOY_SCRAPE } from "@/constants/api";

export default {
  name: "TestNIDInfo",
  metaInfo: { title: "Test - NID Info" },
  components: {},
  data: () => ({
    type: "scraper",
    loading: false,
    parsedNidData: null,
    errorData: null,
    nid: null,
    dob: null,
    items: [
      { name: "Nid", value: (item) => item.nid },
      { name: "Name (Bengali)", value: (item) => item.nameBn },
      { name: "Name (English)", value: (item) => item.nameEn },
      { name: "Date Of Birth", value: (item) => item.dateOfBirth },
      { name: "Blood Group", value: (item) => item.bloodGroup },
      { name: "Father Name (Bengali)", value: (item) => item.fatherNameBn },
      { name: "Father Name (English)", value: (item) => item.fatherNameEn },
      { name: "Mother Name (Bengali)", value: (item) => item.motherNameBn },
      { name: "Mother Name (English)", value: (item) => item.motherNameEn },
      { name: "Occupation (Bengali)", value: (item) => item.occupationBn },
      { name: "Occupation (English)", value: (item) => item.occupationEn },
      { name: "PIN", value: (item) => item.pin },
      { name: "Spouse Name (Bengali)", value: (item) => item.spouseNameBn },
      { name: "Spouse Name (English)", value: (item) => item.spouseNameEn },
      { name: "Present Address (Bengali)", value: (item) => item.presentAddressBn.addressLine },
      { name: "Present Address (English)", value: (item) => item.presentAddressEn.addressLine },
      {
        name: "Permanent Address (Bengali)",
        value: (item) => item.permanentAddressBn.addressLine,
      },
      {
        name: "Permanent Address (English)",
        value: (item) => item.permanentAddressEn.addressLine,
      },
    ],
  }),
  watch: {
    $route({ name }) {
      if (name.includes("test.nid-info")) {
        this.bootstrap();
      }
    },
  },
  mounted() {
    this.bootstrap();
  },
  methods: {
    bootstrap() {
      if (this.$route.path.includes("scraper")) {
        this.type = "scraper";
      } else {
        this.type = "porichoy";
      }
      this.nid = this.$store?.state?.test?.testNidInfoData?.nid;
      this.dob = this.$store?.state?.test?.testNidInfoData?.dob;
      this.parsedNidData = this.$store?.state?.test?.testNidInfoData?.parsedNidData;
    },
    showError(err) {
      console.log(err);
      this.$iziToast.error({ title: err || "Something went wrong!" });
    },
    async parseNid() {
      this.loading = true;
      this.errorData = null;
      this.parsedNidData = null;
      try {
        const data = new FormData();
        data.append("dob", this.dob);
        data.append("nid", this.nid);
        const response = await this.$axios.post(
          this.type === "scraper" ? NID_SCRAPER_SCRAPE : PORICHOY_SCRAPE,
          data
        );
        this.parsedNidData = response.data?.data;
        this.$store.commit("test/setTestNidInfoData", {
          nid: this.nid,
          dob: this.dob,
          parsedNidData: this.parsedNidData,
        });
      } catch (err) {
        this.errorData = err?.response?.data || err?.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.col-sm-8 {
  padding: 30px;
}
</style>
